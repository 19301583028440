import React from "react";

export const NoMatch = React.lazy(() => import("./NoMatch"));
export const Main = React.lazy(() => import("./Main"));
export const SignIn = React.lazy(() => import("./Sign/SignIn"));
export const SignUp = React.lazy(() => import("./Sign/Signup"));
export const LicenseSlide = React.lazy(() => import("./LicenseSlide"));
export const MindMapSlide = React.lazy(() => import("./MindMapSlide"));
export const SignUpSlide = React.lazy(() => import("./SignUpSlide"));
export const AgreementSlide = React.lazy(() => import("./AgreementSlide"));
export const Account = React.lazy(() => import("./Account"));

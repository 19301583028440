import Spin from "antd/es/spin";
import { Suspense } from "react";

interface ISuspenseLoader {
  children: any;
}

export const SuspenseLoader = ({ children }: ISuspenseLoader) => {
  return (
    <Suspense fallback={<Spin fullscreen spinning />}>{children}</Suspense>
  );
};

import { ThemeConfig } from "antd/es/config-provider";

export const AntdTheme: ThemeConfig = {
  token: {
    colorText: "#ffffff",
    colorBgContainer: "#5C5A5A",
    colorBgElevated: "#444444",
    colorBorder: "transparent",
    fontFamily: "OCR A Std",
    borderRadius: 0,
  },
  components: {
    Pagination: {
      colorText: "white",
    },
    Layout: {
      footerBg: "#444444",
      bodyBg: "#444444",
      headerBg: "#2E2E2E",
    },
    Menu: {
      itemBg: "#7B7B7B",
      itemPaddingInline: 0,
      itemBorderRadius: 0,
    },
    Input: {
      activeBg: "#5C5A5A",
      hoverBg: "#5C5A5A",
      activeBorderColor: "transparent",
      hoverBorderColor: "transparent",
      controlHeight: 42,
    },
    Dropdown: {
      colorBgElevated: "#444444",
      colorBgContainer: "#444444",
    },
  },
};

export const StyledComponentsTheme = {
  baseLine: 4,
  colors: {
    white: "#ffffff",
    grayCC: "#cccccc",
    gray44: "#444444",
    Text: {
      color: "#ffffff",
    },
    Menu: {
      itemBg: "#7B7B7B",
    },
    DropDown: {
      itemBg: "#7B7B7B",
      activeBg: "#444444",
    },
  },
};

import Typography from "antd/es/typography/Typography";
import { Link } from "react-router-dom";
import { BlockCenter } from "Src/styled/layout.styled";
import { FooterStyle } from "../lp.styled";

const FooterPage = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterStyle>
      <BlockCenter>
        <Typography>
          <Link to="/agreement">Соглашение</Link> |{" "}
          <Link to="/signup">Получить ключ</Link>
        </Typography>
        <Typography>
          {`© 2014-${currentYear} Все права защищены и принадлежат `}
          <a href="https://lineclub.ru" title="LineClub.RU">
            LineClub.RU
          </a>
        </Typography>
      </BlockCenter>
    </FooterStyle>
  );
};

export default FooterPage;

import Layout from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";
import FooterPage from "./Footer";
import HeaderPage from "./Header";
import { ContentStyle } from "../lp.styled";

const LayoutPage = () => {
  return (
    <Layout>
      <HeaderPage />
      <ContentStyle>
        <Outlet />
      </ContentStyle>
      <FooterPage />
    </Layout>
  );
};

export default LayoutPage;

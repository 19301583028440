import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { TSelectedNode, TGroupGrph } from "Src/Models/ForceGraph";
import { getLocalStorage } from "Src/utils";

export interface GraphState {
  selectedNode: TSelectedNode | null;
  searchNode: string | null;
  historyNode: TSelectedNode[];
  graphType: TGroupGrph;
  pollingInterval: number;
}

export const updatePollingInterval = createAction<number>(
  "updatePollingInterval"
);

const initialState: GraphState = {
  graphType: getLocalStorage<TGroupGrph>("viewGraph", "ForceGraph2D"),
  selectedNode: null,
  searchNode: null,
  pollingInterval: 5000,
  historyNode: [],
};

export const graphSlice = createSlice({
  name: "graph",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updatePollingInterval, (state, ations) => {
      state.pollingInterval = ations.payload;
    });
  },
  reducers: {
    setGraphType: (state, action: PayloadAction<TGroupGrph>) => {
      state.graphType = action.payload;
      localStorage.setItem("viewGraph", action.payload);
    },
    setSearchText: (state, action: PayloadAction<string | null>) => {
      state.searchNode = action.payload;
      if (action.payload) {
        state.selectedNode = null;
      }
    },
    setSelectedNode: (state, action: PayloadAction<TSelectedNode>) => {
      state.selectedNode = action.payload;
      state.searchNode = null;
      const findNode = state.historyNode.some(
        (node) => node.name === state.selectedNode?.name
      );

      if (!findNode) {
        state.historyNode = [action.payload].concat(state.historyNode);
      }
    },
  },
});

export const { setSelectedNode, setGraphType, setSearchText } =
  graphSlice.actions;

export default graphSlice.reducer;

import ConfigProvider from 'antd/es/config-provider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { Provider } from 'react-redux';
import App from 'Src/App';
import { ThemeProvider } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import {AntdTheme, StyledComponentsTheme} from './styled/theme';

const element = document.getElementById('root');

if (element) {
  const root = ReactDOM.createRoot(element);

  root.render(
      <React.StrictMode>
          <Provider store={store}>
              <ConfigProvider theme={AntdTheme}>
                  <ThemeProvider theme={StyledComponentsTheme}>
                      <App />
                  </ThemeProvider>
              </ConfigProvider>
          </Provider>
      </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';

type EmptyObj = Record<PropertyKey, any>;
export type TPath = string | (string | number)[];

export const setToValue = (obj: EmptyObj, path: TPath, value: any) => {
    if (isString(path)) {
        path = path.split('.');
    }

    let o = obj;

    while (path.length - 1) {
        const n = path.shift();

        if (n) {
            if (!(n in o)) o[n] = {};
            o = o[n];
        }
    }
    o[path[0]] = value;
};

export const getPathToValue = (jsonData: EmptyObj, path: TPath, defaultValue?: any) => {
    if (!(jsonData instanceof Object) || isUndefined(path)) {
        throw Error('Not valid argument:jsonData:' + jsonData + ', path:' + path);
    }
    if (isString(path)) {
        path = path.split('.');
    }

    for (let i = 0, n = path.length; i < n; i+=1) {
        const key: any = path[i];

        if (key in jsonData) {
            if (jsonData[key] !== null) {
                jsonData = jsonData[key];
            } else {
                return null;
            }
        } else {
            return defaultValue;
        }
    }

    return jsonData;
};

export function getLocalStorage<T>(key: string, defaultValue: T): T  {
    const store = localStorage.getItem(key);

    if (isString(store)) {
        try {
            const isDataSave = ['[', '{'].some((a) => store.includes(a));

            if( isDataSave ) return JSON.parse(store) as T ;

            return store as T;
        } catch (e) {
            return defaultValue;
        }
    }

    return defaultValue;
}

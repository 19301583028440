/* eslint-disable no-console */
import Dropdown from "antd/es/dropdown";
import Flex from "antd/es/flex";
// import Space from 'antd/es/space';
import { Row, Col } from "antd/es/grid";
import { ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import i18n from "Src/core/i18n";
import style from "Src/styles/dropdown.less";
import { getLocalStorage } from "Src/utils";
import {
  MenuItem,
  MenuWrap,
  HeaderStyle,
  LogoBlock,
  DropDownButton,
  DropDownItem,
} from "../lp.styled";

interface IMenuItem {
  label: ReactElement;
  key: string;
}

const HeaderPage = () => {
  const location = useLocation();
  const [t] = useTranslation();
  const lng = getLocalStorage<string>("lng", "ru");

  useEffect(() => {
    i18n.changeLanguage(lng);
  }, []);

  const changeLng = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const itemsMenu: IMenuItem[] = [
    {
      label: <Link to="/about">{t("Menu.About")}</Link>,
      key: "about",
    },
    {
      label: <Link to="/contact">{t("Menu.ContactUs")}</Link>,
      key: "contactus",
    },
    {
      label: <Link to="/price">{t("Menu.Price")}</Link>,
      key: "price",
    },
    {
      label: <Link to="/api">{t("Menu.Api")}</Link>,
      key: "api",
    },
  ];

  const menuProps = useMemo(() => {
    const resources = i18n?.options?.resources
      ? Object.keys(i18n?.options?.resources)
      : [];

    if (resources.length) {
      const languages = resources.filter((lang) => lang !== i18n.language);

      return {
        items: languages.map((lang) => ({
          label: <DropDownItem>{lang.toUpperCase()}</DropDownItem>,
          key: lang,
          className: style.dropDownMenuItem,
        })),
        className: style.dropDownMenu,
        onClick: (props: any) => {
          changeLng(props.key);
        },
      };
    }

    return {};
  }, [i18n.language]);

  return (
    <HeaderStyle>
      <Row style={{ alignItems: "center", width: "100%" }}>
        <Col flex="auto">
          <Flex>
            <LogoBlock as={location.pathname === "/" ? "div" : Link} to="/" />
            <MenuWrap>
              {itemsMenu.map((item) => {
                return (
                  <MenuItem
                    $active={location.pathname === item.key}
                    key={item.key}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </MenuWrap>
          </Flex>
        </Col>
        <Col flex="80px">
          <Dropdown
            className={style.dropDown}
            menu={menuProps}
            trigger={["click"]}
          >
            <DropDownButton type="text">
              {i18n.language.toUpperCase()}
            </DropDownButton>
          </Dropdown>
        </Col>
      </Row>
    </HeaderStyle>
  );
};

export default HeaderPage;

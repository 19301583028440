import Layout from "antd/es/layout/layout";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useGetSettingsMutation } from "Src/store/features/models";
import { getLocalStorage } from "Src/utils";
import { VideoStyled } from "../lp.styled";
// import { useWindowSize } from "usehooks-ts";

const LayoutEmpty = () => {
  const [getSeetings, result] = useGetSettingsMutation();
  const licenseKey = getLocalStorage<string | null>("licenseKey", null);

  useEffect(() => {
    if (licenseKey) {
      getSeetings(licenseKey);
    }
  }, []);

  return (
    <Layout>
      <Outlet />
      {result.data?.settings.video?.bg && (
        <VideoStyled autoPlay muted loop>
          <source src={result.data?.settings.video?.bg} type="video/mp4" />
          Your browser does not support HTML5 video.
        </VideoStyled>
      )}
    </Layout>
  );
};

export default LayoutEmpty;

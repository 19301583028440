/* eslint-disable no-unreachable */
import Routers from 'Src/Routes/index';
import {LayoutBody} from 'Src/styled/layout.styled';

const App = () => {
    return (
        <LayoutBody>
            <Routers />
        </LayoutBody>
    );
};

// 4BMSw2otDxbEfowH06ILx3zg6c9YFdgXwzhsCOY67AG26Pf7aSqpgJP6wzFPLR2O
export default App;

import i18next,{i18n, InitOptions} from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from 'Src/assets/locale/en.json';
import ru from 'Src/assets/locale/ru.json';

export const defaultNS = 'tranlation';

export const resources = {
    ru: {tranlation: ru}, 
    en: {tranlation: en}, 
} as const

const options: InitOptions = {
    load: 'currentOnly',
    fallbackLng: 'ru',
    debug: process.env.NODE_ENV === 'development',
    defaultNS,
    resources,
    contextSeparator: '#',
    compatibilityJSON: 'v3',
    interpolation: {
        escapeValue: false
    }
};

i18next
    .use(initReactI18next)
    .init(options);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface I18next extends i18n {}

export default i18next;

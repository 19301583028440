// import { ErrorResult } from 'Components/Error';
import ErrorResult from "Src/components/ErrorPages/ErrorResult";
import LayoutPage from "Src/components/LayoutPage/Main";
import LayoutEmpty from "Src/components/LayoutPage/Empty";
import { SuspenseLoader } from "Src/components/Suspense";
import { LazyExoticComponent } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import * as Page from "Src/Pages";

const RouteElement = (Element: LazyExoticComponent<() => JSX.Element>) => {
  return (
    <SuspenseLoader>
      <Element />
    </SuspenseLoader>
  );
};

const Routers = () => {
  const router = createBrowserRouter([
    {
      Component: LayoutPage,
      path: "/",
      errorElement: ErrorResult,
      children: [
        {
          index: true,
          element: RouteElement(Page.Main),
          errorElement: ErrorResult,
        },
        {
          element: RouteElement(Page.SignUp),
          errorElement: ErrorResult,
          path: "signup",
        },
        {
          element: RouteElement(Page.SignIn),
          errorElement: ErrorResult,
          path: "signin",
        },
        {
          element: RouteElement(Page.AgreementSlide),
          errorElement: ErrorResult,
          path: "agreement",
        },
        {
          path: "*",
          element: RouteElement(Page.NoMatch),
        },
      ],
    },
    {
      Component: LayoutEmpty,
      errorElement: ErrorResult,
      path: "/map",
      children: [
        {
          index: true,
          element: RouteElement(Page.MindMapSlide),
          errorElement: ErrorResult,
        },
      ],
    },
    {
      Component: LayoutEmpty,
      errorElement: ErrorResult,
      path: "/account",
      children: [
        {
          index: true,
          element: RouteElement(Page.Account),
          errorElement: ErrorResult,
        },
      ],
    },
    {
      path: "*",
      element: RouteElement(Page.NoMatch),
      errorElement: ErrorResult,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routers;

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { topicsApi } from "./features/topics";
import { structureApi } from "./features/models";
import graphSlice from "./graph";

const reducerCombine = combineReducers({
  [topicsApi.reducerPath]: topicsApi.reducer,
  [structureApi.reducerPath]: structureApi.reducer,
  graphSlice,
});

export const store = configureStore({
  reducer: reducerCombine,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      immutableCheck: true,
      serializableCheck: false,
    }).concat([topicsApi.middleware, structureApi.middleware]);
  },
});

export const { dispatch } = store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

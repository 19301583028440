import styled from "styled-components";

export const Layout = styled.div<{
  $position?: "absolute" | "relative" | "static";
}>`
  width: 100vw;
  min-height: 100vh;
  position: ${(props) => props.$position || "inherit"};
`;

export const LayoutBody = styled(Layout)`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
`;

export const BlockCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PageLayoutCenter = styled(BlockCenter)`
  height: 100vh;
  width: 100vw;
`;

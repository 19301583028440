import Button from "antd/es/button";
import { Content, Header, Footer } from "antd/es/layout/layout";
import Logo from "Src/assets/images/logo1.png";
import styled, { css } from "styled-components";

export const LogoBlock = styled.div<{}>`
  width: 48px;
  height: 48px;
  background-image: url("${Logo}");
  background-position: center;
  background-size: cover;
  margin-right: 10px;
`;

export const MenuWrap = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ContentStyle = styled(Content)`
  position: relative;
`;

export const FooterStyle = styled(Footer)`
  z-index: 1;
`;

export const HeaderStyle = styled(Header)`
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const DropDownButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.DropDown.itemBg};
  width: 113px;
  height: 48px;
  border-radius: 0;
`;

export const DropDownItem = styled("button")`
  background-color: ${({ theme }) => theme.colors.DropDown.activeBg};
  width: 113px;
  height: 48px;
  border-radius: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.DropDown.itemBg};
  &:hover {
    background-color: ${({ theme }) => theme.colors.DropDown.itemBg};
    border-color: ${({ theme }) => theme.colors.DropDown.activeBg};
  }
`;

export const MenuItem = styled.button<{ $active?: boolean }>`
  ${({ theme, $active }) => {
    return css`
      min-width: 115px;
      background-color: ${$active
        ? theme.colors.white
        : theme.colors.Menu.itemBg};

      margin: 0 5px;
      text-align: center;
      height: 48px;
      & a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: ${theme.colors.white};
      }
    `;
  }}
`;

export const VideoStyled = styled("video")`
  position: fixed;
  left: 0;
  top: 0;
  object-fit: fill;
  width: 100%;
  height: 100%;
`;

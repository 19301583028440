import Button from 'antd/es/button';
import Result from 'antd/es/result';

const ErrorResult = (
    <Result
        extra={[
            <Button key="refresh" onClick={() => window.location.reload()}>
                Перезагрузить страницу
            </Button>
      ]}
        status="error"
        subTitle="Пожалуйста попробуйте еще раз."
        title="Что то случилось"
  />
);

export default ErrorResult
